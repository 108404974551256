<template>
  <app-nav />
  <tripwire-list />
</template>

<script>
// @ is an alias to /src
import AppNav from '@/components/AppNav.vue'
import TripwireList from '@/components/TripwireList.vue'

export default {
  name: 'Tripwire',
  components: {
    AppNav,
    TripwireList
  }
}
</script>
