<template>
<div class="tripwire-list">
<h2>通知</h2>
<p>
検索条件に合致した情報が公示されたことが判明すれば通知します。
通知条件数の上限は100です。<strong>有料サービスが無効だと通知されません。</strong>
</p>
<div v-if="loading" class="loading"></div>
<div class="scroll" v-else-if="items.length > 0">
<table class="list">
  <thead>
    <tr>
    <th>操作</th>
    <th>ラベル</th>
    <th>通知条件</th>
    <th>登録日時</th>
    <th>前回検出日時</th>
    </tr>
  </thead>
  <tbody v-for="item in items" :key="item.id">
    <tr>
    <td><button @click="remove(item.id)">削除 <fa icon="minus" /></button></td>
    <td>{{ item.label }}</td>
    <td class="query">
      <span v-if="item.query.t">種類：{{ item.query.t }}</span>
      <span v-if="item.query.c">裁判所：{{ item.query.c }}</span>
      <span v-if="item.query.n">名前：{{ item.query.n }}</span>
      <span v-if="item.query.a">住所：{{ item.query.a }}</span>
    </td>
    <td>{{ item.added }}</td>
    <td>{{ item.fired }}</td>
    </tr>
  </tbody>
</table>
</div>
<div v-else>
通知条件は登録されていません。
</div>
</div>
</template>

<script>
import axios from 'axios'
import config from '@/config'

export default {
	name: 'TripwireList',
  data() {
		return {
			items: [],
      loading: true
		}
	},
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      axios.post(config.API+'tripwire.php', {
        action: 'get'
      },
      {
        withCredentials: true
      }).then(res => {
        if(res.data.status == 'success') {
          this.items = res.data.items;
          for (let i in this.items) {
            this.items[i].query = JSON.parse(this.items[i].query);
          }
        }
        this.loading = false;
      });
    },
    remove(id) {
      this.loading = true;
      axios.post(config.API+'tripwire.php', {
        action: 'remove',
        id: id
      },
      {
        withCredentials: true
      }).then(res => {
        if(res.data.status == 'success') {
          this.load();
          return;
        }
        alert(res.data.message);
        this.loading = false;
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tripwire-list {
  padding: 4pt;
}
.tripwire-list td.query {
  white-space: normal;
}
.tripwire-list td.query span {
  white-space: nowrap;
  margin: 0 4pt;
}
</style>
