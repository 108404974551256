<template>
  <div class="app-nav">
    <div v-if="loading" class="loading"></div>
    <div v-else>
    <div v-if="!user.id" class="user"><router-link to="/login">ログイン・会員登録</router-link></div>
    <div v-else class="user">
      {{ user.name }}様
      <span v-if="user.expire">
      有料版期限: {{ user.expire }}
      </span>
      <span v-else>
      無料版
      </span>
      <a @click="logout" href="#">
      ログアウト
      </a>
    </div>
    <nav v-if="user.id">
      <router-link to="/">検索</router-link>
      <span v-if="user.expire">
      | <router-link to="/tripwire">通知条件</router-link>
      </span>
      | <router-link to="/purchase">有料サービス</router-link>
      | <router-link to="/userinfo">会員情報</router-link>
      <span v-if="user.role == 0"> | <router-link to="/admin">管理</router-link></span>
    </nav>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import config from '@/config'

export default {
	name: 'AppNav',
  data() {
		return {
      user: false,
      loading: true
		}
	},
  mounted() {
    // ログイン情報取得
    axios.post(config.API+'me.php', {},
    {
      withCredentials: true
    }).then(res => {
      if(res.data.status == 'success') {
        this.user = res.data.user;
      }
      this.loading = false;
      this.$emit('onload', this.user);
    });
  },
  methods: {
    logout() {
      this.loading = true;
      axios.post(config.API+'logout.php', {},
      {
        withCredentials: true
      }).then(res => {
        if (res.data.status == 'success') {
          // リロード
          this.$router.push({path: '/', force: true});
        }
      });
    }
	}
}
</script>

<style scoped>
div.app-nav .user {
  text-align: right;
  margin: 0 4pt;
}
div.app-nav nav {
  padding: 4pt;
}
</style>
